import React from 'react';
import axios from 'axios';
import arrow from '../images/Arrow.svg';

const JoinNowComponent = ({ userUuid }) => {
  const urlPath = window.location.href;

  return (
    <div className="custom-card mt-4 py-3">
      <h5 style={{ color: '#F18831' }} className="f700 text-uppercase">
        FOR INDIVIDUALS WITH DIABETES
      </h5>
      <div className="mx-3 text-center primary-text-color">
        <div className="f900  py-3" style={{ fontSize: '1.4rem' }}>
          MAKE A CHANGE WITH DLP FOR BETTER QUALITY OF LIFE
        </div>

        <div className="mb-3">
          Click below to experience* the{' '}
          <div>
            <b>DLP Digital Education & Support Web-based App</b>
          </div>
        </div>

        <a
          onClick={() => {
            axios.post('/api/user/update/user', {
              uuid: userUuid,
            });
          }}
          href={
            urlPath.includes('localhost') || urlPath.includes('temp9.')
              ? `https://dlp.thedgroup.com.my/patient?user_id=${userUuid}`
              : `https://lifestylechange.mydlp.my/patient?user_id=${userUuid}`
          }
          target="_blank"
          rel="noreferrer"
        >
          <button className="register-button px-3">
            JOIN NOW <img src={arrow} alt="arrow" />
          </button>
        </a>

        <div
          className="mt-3"
          style={{ fontSize: '0.8rem', lineHeight: '1rem' }}
        >
          *Enrolment by a healthcare professional who is a DLP Advocate is
          required for a complete experience. Speak to your healthcare provider
          to find out if they are a DLP Advocate!
        </div>
      </div>
    </div>
  );
};

export default JoinNowComponent;
