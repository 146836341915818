import React from 'react';
import arrow from '../images/Arrow.svg';

const RegisterNowComponent = ({ userUuid }) => {
  const urlPath = window.location.href;

  return (
    <div className="custom-card my-4 py-3">
      <h5 style={{ color: '#F18831' }} className="f700 text-uppercase">
        FOR HEALTHCARE PROVIDERS
      </h5>

      <div className="mx-3 text-center primary-text-color">
        <div className="f900  py-3" style={{ fontSize: '1.4rem' }}>
          BE AN ADVOCATE FOR DIABETES LIFESTYLE MANAGEMENT
        </div>
        <p>
          Join more than 4,000 HCPs in Malaysia as a full-fledged DLP Advocate &
          receive FREE patient tools that support your daily practice.
        </p>

        <a
          href={
            urlPath.includes('localhost') || urlPath.includes('temp9.')
              ? `https://abbottcrm.thedgroup.com.my/login`
              : `https://onlinecourse.mydlp.my/login`
          }
          target="_blank"
          rel="noreferrer"
        >
          <button className="register-button px-3">
            REGISTER NOW <img src={arrow} alt="arrow" />
          </button>
        </a>
      </div>
    </div>
  );
};

export default RegisterNowComponent;
