import React from 'react';
import img1 from '../images/common-life-1.svg';
import img2 from '../images/common-life-2.svg';
import img3 from '../images/common-life-3.svg';
import img4 from '../images/common-life-4.svg';

const CommonRiskComponent = () => {
  const commonRiskList = [
    { src: img1, alt: 'common-risk-1' },
    { src: img2, alt: 'common-risk-2' },
    { src: img3, alt: 'common-risk-3' },
    { src: img4, alt: 'common-risk-4' },
  ];
  return (
    <div className="text-center custom-card p-3">
      <div
        className="f900 text-uppercase primary-text-color py-3"
        style={{ fontSize: '1.4rem' }}
      >
        COMMON RISK FACTORS OF TYPE 2 DIABETES
      </div>
      <center>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {commonRiskList.map((img, index) => (
            <img
              key={index}
              src={img.src}
              alt={img.alt}
              style={{ height: 'auto', margin: '1%' }}
              className="dietary-img"
            />
          ))}
        </div>
      </center>

      <div className="primary-text-color mt-3">
        <p>
          Reference: Clinical Practice Guidelines: Management of Type 2 Diabetes
          Mellitus, 6th Edition. Putrajaya: Ministry of Health, Malaysia, 2020.
        </p>
      </div>
    </div>
  );
};

export default CommonRiskComponent;
